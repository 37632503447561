/**
 * Global namespaces for this solution.
 */
module.exports = {
  /**
   * Namespace to refer common helpers and sprites from templates.
   * @example
   * {{sprite "common/file.svg"}}
   * {{> common/partial.hbs}}
   */
  common: 'common',
  /**
   * Namespace for publically exposed script modules,
   * app-specific data attributes,
   * CSS scopes.
   * @example
   * window.se2
   * .se2--scope {}
   */
  public: 'se2',
};
