module.exports = {
  /**
   * Left-to-right text direction.
   */
  ltr: 'ltr',
  /**
   * Right-to-left text direction.
   */
  rtl: 'rtl',
};
