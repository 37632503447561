module.exports = {
  /**
   * Published when accessibility mode is being enabled or disabled.
   * Payload: boolean.
   * @example
   * publishMessage(messages.accessibility, true);
   */
  accessibility: 'accessibility',
  /**
   * Published when BannerImageAndDataLayers component is loaded.
   * @example
   * publishMessage(messages.bannerImageAndDataLayersIsLoaded);
   */
  bannerImageAndDataLayersIsLoaded: 'bannerImageAndDataLayersIsLoaded',
  /**
   * Published when current breakpoint changes.
   * Payload: string, key of `configs/breakpoints`.
   * @example
   * publishMessage(messages.breakpoint, 'xl');
   */
  breakpoint: 'breakpoint',
  /**
   * Published when debug mode is being enabled or disabled.
   * Payload: boolean.
   * @example
   * publishMessage(messages.debug, true);
   */
  debug: 'debug',
  /**
   * Published when embedded chat changes own state.
   * Payload: { state:  'available' | 'unavailable' }, state of the embedded chat.
   * @example
   * publishMessage(messages.embeddedChat, { state: 'available' });
   */
  embeddedChat: 'embeddedChat',
  /**
   * Published when focus moves to first region element in country selector.
   * @example
   * publishMessage(messages.focusFirstRegionOfCountrySelector);
   */
  focusFirstRegionOfCountrySelector: 'focusFirstRegionOfCountrySelector',
  /**
   * Published when focus should move to first focusable element in header.
   * @example
   * publishMessage(messages.focusHeader);
   */
  focusHeader: 'focusHeader',
  /**
  * Published when form submit resolved.
  * Statuses:
  * 200 - accepted,
  * 300 - redirected,
  * 400 - rejected,
  * 500 - serverError.
  * Payload: { status:  accepted | redirected | rejected | serverError }, state of the form submit.
  * @example
  * publishMessage(messages.formSubmit, { status: accepted });
  */
  formSubmit: 'formSubmit',
  /**
   * Published when header is mounted.
   * @example
   * publishMessage(messages.headerMount);
   */
  headerMount: 'headerMount',
  /**
  * Published when height of header changes.
  * @example
  * publishMessage(messages.headerHeight);
  */
  headerHeight: 'headerHeight',
  /**
  * Published when header  animation  start - opened widget should be closed.
  * @example
  * publishMessage(messages.headerInMoving);
  */
  headerInMoving: 'headerInMoving',
  /**
  * Published when body gets isUsingKeyboard class name.
  * @example
  * publishMessage(messages.isKeyboardUsed, { isKeyboardUsed: true });
  */
  isKeyboardUsed: 'isKeyboardUsed',
  /**
   * Published when state of modal popup changes.
   * Payload: { isOpen: boolean }, state of the modal.
   * @example
   * publishMessage(messages.breakpoint, { isOpen: true });
   */
  modal: 'modal',
  /**
   * Used for geolocation
   */
  openCountrySelector: 'openCountrySelector',
  /**
   * Used for countrySelectorModal
   */
  openCountrySelectorModal: 'openCountrySelectorModal',
  /**
   * Used for testing and health check purposes.
   */
  ping: 'ping',
  /**
   * Published in response to "ping" message.
   */
  pong: 'pong',
  /*
   * Published when state of recaptcha changes.
   * Payload: { isLoading: boolean }, state of the recaptcha.
   * @example
   * publishMessage(messages.recaptcha, { isLoading: true });
   *
   * @example
   * publishMessage(messages.recaptcha, { resolved: true });
   */
  recaptcha: 'recaptcha',
  /**
   * Published when state of smartbanner changes.
   * Payload: { isOpen: boolean }, state of the smartbanner.
   * @example
   * publishMessage(messages.smartbanner, { isOpen: true });
   */
  smartbanner: 'smartbanner',
  /**
   * Published when need to initialize slider in MegaMenu;
   */
  initSliderMM: 'initSliderMM',
  /**
   * Used to logout user
   */
  ssoLogout: 'ssoLogout',
  /**
   * Published when SSO wants to update user name
   * @example
   * publishMessage(messages.ssoUserNameUpdated, { name: 'Johny', surname: 'Walker' })
   */
  ssoUserNameUpdated: 'ssoUserNameUpdated',
  /**
   * Published when gsap timelines of sticky elements should be refreshed.
   * @example
   * publishMessage(messages.refreshTimelines);
   */
  refreshTimelines: 'refreshTimelines',
  /**
   * Published when state of breadcrumbs changes.
   * Payload: { isVisible: boolean }, state of the breadcrumbs.
   * @example
   * publishMessage(messages.breakpoint, { isVisible: true });
   */
  breadcrumbs: 'breadcrumbs',
  /**
   * Published when products adds to cart.
   * Payload: { message: string }, message when products have been added to cart.
   * @example
   * publishMessage(messages.cartUpdatedByProductSelector, { message: 'You just added items to your Products.' });
   */
  cartUpdatedByProductSelector: 'cartUpdatedByProductSelector',
  /**
   * Published when data in the table was changed.
   * Payload: boolean.
   * @example
   * publishMessage(messages.sortTableData);
   */
  sortTableData: 'sortTableData',
  /**
   * Published when user click close button on desktop menu Header version 7 and higher.
   * Payload: { selector: close button selector }.
   * @example
   * publishMessage(messages.userClickedMegaMenuCloseButtonOnDesktop, { selector: closeDesktopMenuButtonSelector })
   */
  userClickedMegaMenuCloseButtonOnDesktop: 'userClickedMegaMenuCloseButtonOnDesktop',
};
