import { countryCodes, i18n, languageCodes } from 'configs';

import getCountryLanguageCodes from './getCountryLanguageCodes';
import getTaxonomy from './getTaxonomy';

/**
 * @param { { href: string, pathname: string } } location
 * @returns { PageSettings }
 */
export default function getPageSettings({ href, pathname } = window.location) {
  // TODO: check the need to replace gb country code with uk
  const { country, language } = i18n.attributes;
  const element = document.querySelector(`[${country}][${language}]`);
  let countryCode, languageCode;
  if (element) {
    countryCode = element.getAttribute(country);
    languageCode = element.getAttribute(language);
  } else {
    ({ countryCode, languageCode } = getCountryLanguageCodes(href));
  }
  if (!countryCode) countryCode = countryCodes.default;
  if (!languageCode) languageCode = languageCodes.default;
  const taxonomy = getTaxonomy(pathname);

  return {
    countryCode,
    isHomePage: taxonomy.length < 1,
    languageCode,
    locale: `${languageCode.toLowerCase()}-${countryCode.toUpperCase()}`,
    taxonomy,
  };
}
