/**
 * List of supported browser versions (market share >=0.2%).
 * Based on https://caniuse.com/usage-table stats.
 */
module.exports = {
  android: '>=94',
  chrome: '>=79',
  edge: '>=90',
  ios: '>=13',
  samsung: '>=11',
  firefox: '>=80',
  opera: '>=78',
  safari: '>=13',
};
