module.exports = {
  hidden: -100,
  background: -2,
  below: -1,
  zero: 0,
  one: 1,
  two: 2,
  default: 10,
  above: 20,
  dropdown: 30,
  sticky: 40,
  mask: 50,
  lowerFixed: 55,
  fixed: 60,
  menu: 65,
  popup: 70,
  notification: 80,
  hotspot: 100,
  hotspotHover: 101,
};
