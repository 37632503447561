import Bowser from 'bowser';

import eq from 'lodash/eq';
import find from 'lodash/find';
import first from 'lodash/first';
import partial from 'lodash/partial';

import { browsers, devices, systems } from 'configs';

const lookup = (map, value) => find(map, partial(eq, value)) ?? map.unknown;

/**
 * @param { string } userAgent
 * @returns { PlatformSettings }
 */
export default function getPlatformSettings(userAgent = navigator.userAgent) {
  const {
    browser: { name: browser = '', version = '' } = {},
    platform: { type: device = '' } = {},
    os: { name: system = '' } = {},
  } = Bowser.parse(userAgent);

  return {
    browser: lookup(browsers, browser),
    device: lookup(devices, device),
    system: lookup(systems, system),
    version: parseFloat(first(version.match(/^(\d+\.\d+)/)) ?? '0'),
  };
}
