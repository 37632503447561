module.exports = {
  defaults: {
    environment: 'UAT',
  },
  downloadLinkParams: [
    ['p_Doc_Ref', 'documentId'],
    ['p_File_Name', 'documentName'],
    ['p_enDocType', 'documentType'],
  ],
  environmentOrigins: {
    prod: [
      'https://www.se.com',
      'https://www.schneider-electric.cn',
      'https://www.apc.com',
    ],
    uat: ['*'],
  },
  triggerDownloadParam: 'p_Doc_Ref',
};
