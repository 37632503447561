module.exports = {
  fieldNames: {
    courseId: 'courseId',
    courseName: 'courseName',
    courseRoot: 'courseRoot',
    emailSet: 'emailSettingsTcmURI',
    page: 'thankYouPage',
  },
  queryStringParams: {
    courseId: 'pid',
    courseName: 'pn',
    courseRoot: 'po',
  },
};
