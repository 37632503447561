const unkwnown = require('./unknown');

module.exports = {
  ...unkwnown,
  chrome: 'Chrome',
  firefox: 'Firefox',
  edge: 'Microsoft Edge',
  safari: 'Safari',
  googlebot: 'Googlebot',
};
