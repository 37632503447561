import { countryCodes, languageCodes } from 'configs';

const regExp = /(?:\/|\.)(?<countryCode>[a-z]{2})\/(?<languageCode>[a-z]{2})(?=$|\/)/im;

/**
 * Extracts and returns country and language codes from the URL passed.
 * Expects URLs having formats:
 *  * https://www.se.com/fr/fr/work/campaign/innovation/overview.jsp
 *  * https://www.schneider-electric.cn/zh/work/campaign/innovation/overview.jsp
 * If URL does not have expected codes, default codes are returned.
 * @param {string} url
 * URL to extract the codes.
 * @returns {{ countryCode: string, languageCode: string }}
 */
const getCountryLanguageCodes = (url) => regExp.exec(url)?.groups ?? {
  countryCode: countryCodes.default,
  languageCode: languageCodes.default,
};

export default getCountryLanguageCodes;
