const browsers = require('./browsers');
const devices = require('./devices');
const { public: namespace } = require('./namespaces');
const systems = require('./systems');

const toClassName = (name) => `${namespace}--${
  name.replace(/\W+/g, '--').replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}`;

/**
 * @template T
 * @param {T} map
 * @returns {Readonly<T>}
 */
const toClassMap = (map) => Object.freeze(
  Object.fromEntries(
    Object.values(map).map(
      (value) => [value, toClassName(value)],
    ),
  ),
);

const classNames = {
  accessibility: `${namespace}-accessibility`,
  accessibleVideo: 'accessible-video',
  active: 'active',
  browsers: toClassMap(browsers),
  button: 'button',
  close: 'close',
  devices: toClassMap(devices),
  disabled: 'disabled',
  expanded: 'expanded',
  hide: 'hide',
  iconNoImage: 'icon-no-img',
  invisible: 'invisible',
  invalid: 'invalid',
  keyboard: `${namespace}-keyboard`,
  lazy: `${namespace}-lazy`,
  loaded: `${namespace}-loaded`,
  loading: `${namespace}-loading`,
  open: 'open',
  purposeTextGreenAnim: 'purpose-text-green-anim',
  purposeLineContainer: 'purpose-line-container',
  purposeLine: 'purpose-line',
  queryErrorMessage: 'query-error-message',
  sdlBody: 'sdl-body',
  show: 'show',
  submitButton: 'submit-button',
  systems: toClassMap(systems),
  transparent: 'transparent',
  videoPlayButton: 'video-play-button',
  videoThumbnail: 'video-thumbnail',
  visuallyHidden: 'visuallyhidden',
};

const variableNames = {
  backgroundImageURL: '--background-image-url',
  footerHeight: '--footer-height',
  headerHeightDesktop: '--header-height-desktop',
  headerHeightReduced: '--header-height-reduced',
  logosHighlightHeight: '--logos-highlight-height',
  tabsInlinePadding: '--tabs-inline-padding',
  tooltipOffset: '--tooltip-offset',
  totalStickyHeight: '--total-sticky-height',
  windowHeightInner: '--window-height-inner',
};

const colors = {
  white: '#ffffff',
  purposeGreen: '#44cd5d',
};

module.exports = {
  classNames,
  colors,
  toClassName,
  variableNames,
};
